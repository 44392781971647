<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" >
        <div class="sale" >
            <!-- 顶部导航 -->
            <div class="box-card" shadow="never">
                <!-- 按钮 -->
                <el-row style="height:40px;">
                    <el-button type="primary" @click="exportData" class="buttons">下载Excel</el-button>
                    <div style="display:inline-block;margin-left:20px;">
                        <el-upload
                        :action="uploadUrl"
                        :multiple="false"
                        :show-file-list="false"
                        :headers="authorizationBearer"
                        :on-success="uploadFile">
                        <el-button type="success" class="buttons">回传Excel</el-button>
                    </el-upload>
                    </div>
                    <el-button style="margin-left:20px;" type="primary" @click="confirmSave" class="buttons">确认保存</el-button>
                </el-row>
                <!-- 搜索表单 -->
                <el-form :inline="true" class="demo-form-inline" >
                    <el-form-item>
                        <el-input
                            placeholder="请输入关键词搜索"
                            prefix-icon="el-icon-search"
                            v-model="req.keywords"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">搜索</el-button>
                        <!-- <el-button @click="onReset">重置</el-button> -->
                    </el-form-item>
                </el-form>
                <!-- 表格 -->
                <table class="mytable" style="table-layout:fixed;">
                    <colgroup>
                        <col width="30">
                        <col width="30">
                        <col width="80">
                        <col width="80" v-for="(i,k) in wareHouseList" :key="k">
                    </colgroup>
                    <thead class="thead" id="thead" >
                        <tr class="rows">
                            <td class="cols">skuid</td>
                            <td class="cols">sku名称</td>
                            <td class="cols">宝贝名称</td>
                            <td class="cols" width="80" v-for="(i,k) in wareHouseList" :key="k">{{i.name}} /  成本</td>
                        </tr>
                    </thead>
                </table>
                <div 
                    :style="{height:(__tableHeight__-80)+'px'}"
                    style="overflow-x:hidden;overflow-y:auto;">
                    <table class="mytable" style="table-layout:fixed;" >
                        <colgroup>
                            <col width="30">
                            <col width="30">
                            <col width="80" >
                            <col width="80" v-for="(i,k) in wareHouseList" :key="k">
                        </colgroup>
                        <tbody>
                            <tr class="rows" v-for="(item,key) in tableData" :key="key" :class="{odd:key%2===0}">
                                <td class="cols">{{item.skuid}}</td>
                                <td class="cols">{{item.sku.join(',')}}</td>
                                <td class="cols">{{item.goods_name}}</td>
                                <td class="cols" width="80" v-for="(i,k) in item.warehouse" :key="k" >
                                    {{i.count}}  <span style="color:blue;">/</span>  {{i.price}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>

        <!-- 页码 -->
        <div class="pageDiv" style="">
            <el-pagination
                style="margin:10px 0;"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="req.page"
                :page-size="req.limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                class="flex-right">
            </el-pagination>
        </div>

        <!-- 创建分享 -->
        <el-dialog
        :modal-append-to-body="false"
        title="添加商品"
        :visible.sync="createShareFlag"
        v-if="createShareFlag"
        :close-on-click-modal="false"
        width="80%"
        center>
            <createShare  :id="currentId" @fleshList="getList" @shareExit="shareExit"></createShare>
        </el-dialog>

        <!-- 分享列表 -->
        <el-dialog
        :modal-append-to-body="false"
        title="分享列表"
        :visible.sync="shareListFlag"
        v-if="shareListFlag"
        width="80%"
        center>
            <shareList 
            :id="currentId" 
            :warehouseId="req.warehouse_id" 
            ></shareList>
        </el-dialog>

        <el-dialog
        :modal="false"
        title="大图"
        :visible.sync="showPicFlag"
        width="600px">
        <div style="max-width:800px;max-height:500px;">
            <img style="width:100%;" :src="showSrc" alt="">
        </div>
        </el-dialog>

        <el-dialog
            :modal-append-to-body="false"
            title="导出数据"
            :visible.sync="exportExcelFlag"
            v-if="exportExcelFlag"
            width="60%"
            center>
                <div style="height:50px;width:100%;text-align:center;">
                    <i class="el-icon-loading" v-if="exportStatus===1" style="font-size:50px;color:#000;"></i>
                    <i class="el-icon-success" v-if="exportStatus===2" style="font-size:50px;color:green;"></i>
                </div>
                <div v-if="exportStatus===1" style="height:50px;width:100%;text-align:center;font-size:18px;line-height:50px;">
                    数据正在导出...稍后会自动下载，请留意下载工具
                </div>
                <div v-if="exportStatus===2" style="height:50px;width:100%;text-align:center;font-size:18px;line-height:50px;">
                    数据已导出完毕，本窗口即将关闭
                </div>
        </el-dialog>

    </div>
</template>

<script>
import createShare from './creatDialog';
import shareList from './listDialog';
import apis from "../../common/js/api2";
import tools from "../../../extends/tools";
import methods from "./method";
export default {
props:{

    },
    inject: ['selectSide'], 
    data(){
        return {
            baseUrl: tools.baseURL(),
            uploadUrl:tools.baseURL() + 'api/warehouse/beginning/import',
            authorizationBearer: tools.getToken(),
            //上传图片列表地址
            uploadImages: [

            ],
            exportStatus:false,
            createShareFlag:false,
            shareListFlag:false,
            showPicFlag:false,
            showSrc:'',
            currentId:0,
            tableData:[],
            total:0,
            currentPage:1,
            shareId:0,
            wareHouseList:[],
            exportExcelFlag:false,
            req:{
                limit:30,
                page:1,
                keywords:'',
                warehouseId:''
            }
        }
    },
    components:{
        createShare,shareList
    },
    methods:methods,
   async created(){
        let loader = tools.loading(false,this);
        //初始化数据源
        await apis.stockInitSource().then(res=>{
            if(tools.msg(res,this)){
                this.wareHouseList = res.data.warehouse_infos;
                // this.wareHouseList = [];
            }
            tools.loading(loader,this);
        }).catch(err=>{
            tools.err(err,this);
            tools.loading(loader,this);
        });

        await this.getList();
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.filexls {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}
.cols{
    padding:10px;
}
</style>