import apis from "../../common/js/api2";
import tools from "../../../extends/tools";

export default {
    onSubmit() {
        this.getList();
    },
    // 获取上传的地址
    uploadFile(file) {
        if (file.code === 200) {
            this.$message({
                type: 'success',
                message: '上传成功'
            });
            this.getList();
        } else {
            this.$message({
                type: 'error',
                message: file.msg
            });
        }
    },
    inputSave() {
        // 保存用户输入



    },
    confirmSave() {
        this.$confirm('确认要保存吗？此操作只允许保存一次', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            // 保存
            let loader = tools.loading(false, this);
            apis.confirmStock().then(res => {
                if (tools.msg(res, this)) {

                }
                tools.loading(loader, this);
            }).catch(err => {
                tools.err(err, this);
                tools.loading(loader, this);
            });

            this.$message({
                type: 'success',
                message: '提交成功!'
            });
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消'
            });
        });


    },
    //导出Excel
    exportData() {
        this.exportExcelFlag = true;
        apis.downInitExcel().then(res => {
            // alert(1);
            this.exportStatus = 2;
            let blob = new Blob([res]);
            let url = URL.createObjectURL(blob);
            setTimeout(() => {
                this.exportExcelFlag = false;
                this.exportStatus = 1;
            }, 2500);
            // 下载该blob链接
            tools.downloadFile(url, document.title + tools.getDateTime() + '.xls');
        }).catch(err => {
            console.log(err);
            // this.exportStatus = 3;
        });
    },

    handleSizeChange(val) {
        this.req.limit = val;
        this.getList();
    },
    handleCurrentChange(val) {
        this.req.page = val;
        this.getList();
    },
    async getList() {
        await apis.stockInit(this.req).then((res) => {
            res.data.data.forEach(item => {
                item.warehouse = [];
                if (!item.warehouse_infos) {
                    return;
                }
                let w = tools.copy(item.warehouse_infos);

                for (let i in w) {
                    let obj = this.getWareHouse(i);

                    let c = {
                        id: obj.id,
                        name: obj.name,
                        count: w[i],
                        price: tools.setPrice(item.cost_infos[i])
                    };

                    item.warehouse.push(c);
                }
            });

            this.tableData = tools.copy(res.data.data);
            this.total = res.data.total;
        }).catch((err) => {
            tools.err(err, this);
        });
    },
    getWareHouse(id) {
        let w = {};
        this.wareHouseList.forEach((ii, kk) => {
            if (ii.id > 0 && ii.id == id) {
                ii.count = 0;
                w = ii;
            }
        })

        return w;
    },
    // 页面更新库存
    async updateStock(warehouse, skuid) {
        let save = {};
        save[skuid] = {};
        save[skuid][warehouse.id] = warehouse.count;
        // 
        let loader = tools.loading(false, this);
        apis.updateStock({
            sku: JSON.stringify(save)
        }).then(res => {
            tools.msg(res, this, 1);
            this.getList().then(res => {
                tools.loading(loader, this);
            }).catch(err => {
                tools.loading(loader, this);
            });
        }).catch(err => {
            tools.err(err, this);
            tools.loading(loader, this);
        });
    },
    exit() {
        this.$emit('shareExit')
    },
    shareExit() {
        this.createShareFlag = false;
        this.shareListFlag = false;
    },
    showPic(src) {
        this.showPicFlag = true;
        this.showSrc = src;
    },
    remove(id, key) {
        this.$confirm('确定删除此记录?', '删除确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            apis.goodsDelete({ id: id }).then((info) => {
                if (info.code == 200) {
                    this.tableData.splice(this.tableData.findIndex(item => item.id === id), 1);
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                } else {
                    this.$message({
                        type: 'error',
                        message: info.msg
                    });
                }
            })
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });
        });
    },
    edit(id) {
        this.createShareFlag = true;
        this.currentId = id;
    },
    log(item) {
        this.currentId = item.id;
        this.$router.push({
            path: '/stock/log',
            query: {
                id: this.currentId,
                warehouseId: this.req.warehouse_id
            }
        })
    },
    fenbu(id) {
        this.shareListFlag = true;
        this.currentId = id;
    }
}